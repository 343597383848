/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React, { lazy } from 'react';

// not lazy loaded since this is the home page.
import Welcome from 'src/views/CRUD/welcome/Welcome';

const ChartCreation = lazy(
  () =>
    import(/* webpackChunkName: "ChartCreation" */ 'src/pages/ChartCreation'),
);
const AnnotationLayersList = lazy(
  () =>
    import(
      /* webpackChunkName: "AnnotationLayersList" */ 'src/views/CRUD/annotationlayers/AnnotationLayersList'
    ),
);
const AlertList = lazy(
  () =>
    import(
      /* webpackChunkName: "AlertList" */ 'src/views/CRUD/alert/AlertList'
    ),
);
const AnnotationList = lazy(
  () =>
    import(
      /* webpackChunkName: "AnnotationList" */ 'src/views/CRUD/annotation/AnnotationList'
    ),
);
const ChartList = lazy(
  () => import(/* webpackChunkName: "ChartList" */ 'src/pages/ChartList'),
);
const CssTemplatesList = lazy(
  () =>
    import(
      /* webpackChunkName: "CssTemplatesList" */ 'src/views/CRUD/csstemplates/CssTemplatesList'
    ),
);
const DashboardList = lazy(
  () =>
    import(
      /* webpackChunkName: "DashboardList" */ 'src/views/CRUD/dashboard/DashboardList'
    ),
);
const DashboardRoute = lazy(
  () =>
    import(
      /* webpackChunkName: "DashboardRoute" */ 'src/dashboard/containers/DashboardRoute'
    ),
);
const DatabaseList = lazy(
  () =>
    import(
      /* webpackChunkName: "DatabaseList" */ 'src/views/CRUD/data/database/DatabaseList'
    ),
);
const DatasetList = lazy(
  () =>
    import(
      /* webpackChunkName: "DatasetList" */ 'src/views/CRUD/data/dataset/DatasetList'
    ),
);

const AddDataset = lazy(
  () =>
    import(
      /* webpackChunkName: "DatasetEditor" */ 'src/views/CRUD/data/dataset/AddDataset/index'
    ),
);

const ExecutionLog = lazy(
  () =>
    import(
      /* webpackChunkName: "ExecutionLog" */ 'src/views/CRUD/alert/ExecutionLog'
    ),
);
const ExplorePage = lazy(
  () => import(/* webpackChunkName: "ExplorePage" */ 'src/explore/ExplorePage'),
);
const QueryList = lazy(
  () =>
    import(
      /* webpackChunkName: "QueryList" */ 'src/views/CRUD/data/query/QueryList'
    ),
);
const SavedQueryList = lazy(
  () =>
    import(
      /* webpackChunkName: "SavedQueryList" */ 'src/views/CRUD/data/savedquery/SavedQueryList'
    ),
);

const RegistrationForm = lazy(
  () =>
    import(
      /* webpackChunkName: "RegistrationForm" */ 'src/aiser/auth/RegistrationForm'
    ),
);

const LoginForm = lazy(
  () => import(/* webpackChunkName: "LoginForm" */ 'src/aiser/auth/LoginForm'),
);

const ResetPasswordForm = lazy(
  () =>
    import(
      /* webpackChunkName: "ResetPasswordForm" */ 'src/aiser/auth/reset_password/ResetPasswordForm'
    ),
);

const ForgotPasswordForm = lazy(
  () =>
    import(
      /* webpackChunkName: "ForgotPasswordForm" */ 'src/aiser/auth/forgot_password/ForgotPasswordForm'
    ),
);

const ProfilePage = lazy(
  () => import(/* webpackChunkName: "ProfilePage" */ 'src/aiser/profile/index'),
);

const OrganizationList = lazy(
  () =>
    import(
      /* webpackChunkName: "OrganizationList" */ 'src/aiser/organization/OrganizationList'
    ),
);

const OrganizationDetail = lazy(
  () =>
    import(
      /* webpackChunkName: "OrganizationDetail" */ 'src/aiser/organization/OrganizationDetail'
    ),
);

const WorkspaceList = lazy(
  () =>
    import(
      /* webpackChunkName: "WorkspaceList" */ 'src/aiser/workspace/WorkspaceList'
    ),
);

const WorkspaceDetail = lazy(
  () =>
    import(
      /* webpackChunkName: "WorkspaceDetail" */ 'src/aiser/workspace/WorkspaceDetail'
    ),
);

const WorkspaceInfoPage = lazy(
  () =>
    import(
      /* webpackChunkName: "WorkspaceInfoPage" */ 'src/aiser/workspace/pages/settings/InformationPage'
    ),
);

const WorkspaceUsersPage = lazy(
  () =>
    import(
      /* webpackChunkName: "WorkspaceUsersPage" */ 'src/aiser/workspace/pages/settings/UserPage'
    ),
);

const FirstAttemptPage = lazy(
  () =>
    import(
      /* webpackChunkName: "FirstAttemptPage" */ 'src/aiser/apps/firstAttempt/FirstAttemptPage'
    ),
);

const ManualCheckoutPage = lazy(
  () =>
    import(
      /* webpackChunkName: "ManualCheckoutPage" */ 'src/aiser/subscription/ManualCheckoutPage'
    ),
);

const ReceiptCheckoutPage = lazy(
  () =>
    import(
      /* webpackChunkName: "ReceiptCheckoutPage" */ 'src/aiser/subscription/ReceiptCheckoutPage'
    ),
);

const SuperAdminPage = lazy(
  () =>
    import(
      /* webpackChunkName: "SuperAdminPage" */ 'src/aiser/auth/super_admin/SuperAdminPage'
    ),
);

const SubscriptionFormPage = lazy(
  () =>
    import(
      /* webpackChunkName: "SubscriptionFormPage" */ 'src/aiser/subscription/SubscriptionFormPage'
    ),
);

const SuccessPage = lazy(
  () =>
    import(
      /* webpackChunkName: "SuccessPage" */ 'src/aiser/pages/success/SuccessPage'
    ),
);

const SqlLabPage = lazy(
  () => import(/* webpackChunkName: "SqlLabPage" */ 'src/SqlLab/SqlLabIndex'),
);

const PythonLabPage = lazy(
  () => import(/* webpackChunkName: "PythonLabPage" */ 'src/aiser/python-lab'),
);

const PythonSavedPage = lazy(
  () =>
    import(/* webpackChunkName: "PythonSavedPage" */ 'src/aiser/python-saved'),
);

const JupyterNotebookPage = lazy(
  () =>
    import(
      /* webpackChunkName: "PythonLabPage" */ 'src/aiser/jupyter-notebook'
    ),
);

const JupyterNotebookSavedPage = lazy(
  () =>
    import(
      /* webpackChunkName: "PythonLabPage" */ 'src/aiser/jupyter-notebook-saved'
    ),
);

const TestPage = lazy(
  () => import(/* webpackChunkName: "TestPage" */ 'src/aiser/layout/test'),
);

const EmbedChart = lazy(
  () =>
    import(/* webpackChunkName: "TestPage" */ 'src/aiser/embedded_chart/index'),
);

const FormDataPage = lazy(
  () =>
    import(
      /* webpackChunkName: "TestPage" */ 'src/aiser/embedded_chart/FormDataPage'
    ),
);

const PublishingRouter = lazy(
  () => import(/* webpackChunkName: "TestPage" */ 'src/aiser/publish/index'),
);

const ChatToChart = lazy(
  () =>
    import(
      /* webpackChunkName: "ChatToChart" */ 'src/aiser/chat_to_chart/index'
    ),
);

type Routes = {
  path: string;
  Component: React.ComponentType;
  Fallback?: React.ComponentType;
  props?: React.ComponentProps<any>;
}[];

const embedded_routes: Routes = [
  {
    path: '/embed/chart/:id',
    Component: EmbedChart,
  },

  {
    path: '/embed/form_data',
    Component: FormDataPage,
  },

  {
    path: '/chat_to_chart/',
    Component: ChatToChart,
  },
];

export const routes: Routes = [
  {
    path: '/analytics/welcome/',
    Component: Welcome,
  },
  {
    path: '/dashboard/list/',
    Component: DashboardList,
  },
  {
    path: '/analytics/dashboard/:idOrSlug/',
    Component: DashboardRoute,
  },
  {
    path: '/chart/add',
    Component: ChartCreation,
  },
  {
    path: '/chart/list/',
    Component: ChartList,
  },
  {
    path: '/tablemodelview/list/',
    Component: DatasetList,
  },
  {
    path: '/databaseview/list/',
    Component: DatabaseList,
  },
  {
    path: '/savedqueryview/list/',
    Component: SavedQueryList,
  },
  {
    path: '/csstemplatemodelview/list/',
    Component: CssTemplatesList,
  },
  {
    path: '/annotationlayer/list/',
    Component: AnnotationLayersList,
  },
  {
    path: '/annotationlayer/:annotationLayerId/annotation/',
    Component: AnnotationList,
  },
  {
    path: '/analytics/sqllab/history/',
    Component: QueryList,
  },
  {
    path: '/alert/list/',
    Component: AlertList,
  },
  {
    path: '/report/list/',
    Component: AlertList,
    props: {
      isReportEnabled: true,
    },
  },
  {
    path: '/alert/:alertId/log/',
    Component: ExecutionLog,
  },
  {
    path: '/report/:alertId/log/',
    Component: ExecutionLog,
    props: {
      isReportEnabled: true,
    },
  },
  {
    path: '/explore/',
    Component: ExplorePage,
  },
  {
    path: '/superset/explore/p',
    Component: ExplorePage,
  },
  {
    path: '/dataset/add/',
    Component: AddDataset,
  },
  {
    path: '/dataset/:datasetId',
    Component: AddDataset,
  },

  {
    path: '/register',
    Component: RegistrationForm,
  },
  {
    path: '/login',
    Component: LoginForm,
  },
  {
    path: '/auth/forgot_password',
    Component: ForgotPasswordForm,
  },
  {
    path: '/auth/reset_password/:token/',
    Component: ResetPasswordForm,
  },
  {
    path: '/setup/',
    Component: FirstAttemptPage,
  },
  {
    path: '/user/settings/',
    Component: ProfilePage,
  },
  {
    path: '/apps/',
    Component: OrganizationList,
  },
  {
    path: '/organization/:id/',
    Component: OrganizationDetail,
  },
  {
    path: '/workspace/list/',
    Component: WorkspaceList,
  },
  {
    path: '/workspace/:id/',
    Component: WorkspaceDetail,
  },
  {
    path: '/settings/information/',
    Component: WorkspaceInfoPage,
  },
  {
    path: '/settings/users/',
    Component: WorkspaceUsersPage,
  },
  {
    path: '/subscription/checkout/',
    Component: ManualCheckoutPage,
  },
  {
    path: '/subscription/apply/',
    Component: ReceiptCheckoutPage,
  },
  {
    path: '/super-admin/',
    Component: SuperAdminPage,
  },

  {
    path: '/subscription/form/:id',
    Component: SubscriptionFormPage,
  },

  {
    path: '/success/',
    Component: SuccessPage,
  },

  {
    path: '/analytics/sqllab/',
    Component: SqlLabPage,
  },

  {
    path: '/python_lab/',
    Component: PythonLabPage,
  },

  {
    path: '/python_saved/',
    Component: PythonSavedPage,
  },

  {
    path: '/notebook/',
    Component: JupyterNotebookPage,
  },

  {
    path: '/notebook_saved/',
    Component: JupyterNotebookSavedPage,
  },

  {
    path: '/share/:publishUUID/',
    Component: PublishingRouter,
  },

  {
    path: '/test/',
    Component: TestPage,
  },
  ...embedded_routes,
];

const frontEndRoutes = routes
  .map(r => r.path)
  .reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: true,
    }),
    {},
  );

export function isFrontendRoute(path?: string) {
  if (path) {
    const basePath = path.split(/[?#]/)[0]; // strip out query params and link bookmarks
    return !!frontEndRoutes[basePath];
  }
  return false;
}

const embeddedRoutes = embedded_routes
  .map(r => r.path)
  .reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: true,
    }),
    {},
  );

export function isEmbeddedRoute(path?: string) {
  if (path) {
    const basePath = path.split(/[?#]/)[0]; // strip out query params and link bookmarks
    return !!embeddedRoutes[basePath];
  }
  return false;
}
